/**
 * Enumizes the pathnames of our Intros app
 */
export enum IntrosPathNamesEnum {
	Missing = '/intros',
	Dashboard = '/dashboard',
	OnboardingForm = '/onboarding-form',
	MatchingRules = '/matching-rules',
	Feedback = '/feedback',
	Share = '/intros/share',
	IntroRounds = '/intro-rounds',
	Members = '/members',
	Insights = '/insights',
	Events = '/events',
	Profile = '/profile',
	Onboarding = '/onboarding',
	// Home = '/dashboard',
	CreateCommunity = '/intros/create-community',
	DiscordLoading = '/discord-loading',
	Developer = '/developer',
	DownloadReports = '/downloads',
	InternalAdminPanel = '/admin',
	InternalClientEmulation = '/client-support',
	InternalClubControl = '/club-control',
	InternalTesting = '/testing',
	InternalMonitor = '/monitor',
	Settings = '/settings',
	Matches = '/matches',
	Channels = '/channels',
	CustomizeMemberProfile = '/customize-member-profile',
	Directory = '/admin-directory',
	AIChat = '/admin-ai-chat'
}

/**
 * Enumizes the tab numbers of our Intros pages
 */
export enum IntrosTabNumberEnum {
	OnboardingForm = 0,
	Feedback = 4,
	Members = 7,
	Insights = 8,
	Events = 9,
	Dashboard = 10,
	Settings = 11,
	Profile = 12,
	CreateCommunity = 14,
	Onboarding = 15,
	DiscordLoading = 16,
	Developer = 18,
	IntroRounds = 19,
	DownloadReports = 20,
	KickoffOptions = 21,
	EditWeeklyOptIn = 22,
	MatchingRules = 23,
	Matches = 24,
	Channels = 25,
	CustomizeMemberProfile = 26,
	Directory = 27,
	AIChat = 28,
	InternalAdminPanel = 96,
	InternalClientEmulation = 97,
	InternalClubControl = 98,
	InternalTesting = 99,
	InternalMonitor = 100
}

/**
 * Enumizes notification data
 */
export interface INotificationData {
	/**
	 * Message to display in the notification
	 */
	message: string;
	/**
	 * Type of notification to display
	 */
	type: 'success' | 'error' | 'info' | 'loading';
	/**
	 * True if we should avoid removing the notification on timeout
	 */
	infinite?: boolean;
}

/**
 * Enumizes the Side Drawer Options of the app
 */
export enum SideDrawerOptionsEnum {
	Intros = 'Intros',
	Members = 'Members',
	Insights = 'Insights'
}

/**
 * Information needed about each side drawer option
 */
export interface SideDrawerOptionType {
	name: string;
	tabNumber: number;
	route: string;
}

export const SideDrawerOptions: {
	[key in SideDrawerOptionsEnum]: SideDrawerOptionType;
} = {
	Intros: {
		name: 'Home',
		tabNumber: 10,
		route: IntrosPathNamesEnum.Missing
	},
	Members: {
		name: 'Members',
		tabNumber: 7,
		route: IntrosPathNamesEnum.Members
	},
	Insights: {
		name: 'Insights',
		tabNumber: 8,
		route: IntrosPathNamesEnum.Insights
	}
};
