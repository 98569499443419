import styled from '@emotion/styled';
import { FlexWrapper } from '../../wrappers/styled-divs';
import { AppState } from '../../../../AppModel';

/**
 * Paywall tag plan enum
 */
export enum PaywallTagPlanEnum {
	Starter = 'Starter Plan',
	Pro = 'Pro Plan'
}

/**
 * Paywall tag theme enum
 */
export enum PaywallTagThemeEnum {
	Light = 'Light',
	Dark = 'Dark'
}

export interface IPaywallTagProps {
	/**
	 * The plan to display
	 */
	plan: PaywallTagPlanEnum;
	/**
	 * True if we should disable the click event
	 */
	disableClickEvent?: boolean;
	/**
	 * The theme of the paywall tag
	 */
	theme: PaywallTagThemeEnum;
}

export const PaywallTag = (props: IPaywallTagProps) => {
	const { plan, disableClickEvent, theme } = props;
	return (
		<PaywallTagWrapper
			onClick={() =>
				!disableClickEvent && AppState.setShowPaywallModal(true)
			}
			clickDisabled={disableClickEvent}
			isDarkTheme={theme === PaywallTagThemeEnum.Dark}
		>
			<PaywallTagText>{plan}</PaywallTagText>
		</PaywallTagWrapper>
	);
};

const PaywallTagWrapper = styled.span<{
	isDarkTheme?: boolean;
	clickDisabled?: boolean;
}>`
	display: inline-flex;
	background: ${(props) => (props.isDarkTheme ? '#635BFF14' : '#ffffff')};
	border-radius: 6px;
	padding: 4px 10px;
	border: 1px solid #e6dffb;
	width: fit-content;
	height: fit-content;
	min-height: 16px;
	min-width: fit-content;
	align-items: center;
	cursor: ${(props) => (props.clickDisabled ? '' : 'pointer')};
`;

const PaywallTagText = styled.span`
	color: #7746ff;
	font-size: 12px;
	font-weight: 600;
	font-family: 'Inter';
`;
