import { action, computed, makeObservable, observable } from 'mobx';
import posthog from 'posthog-js';
import { AppState } from '../../../../../../AppModel';
import { RestApiClient } from '../../../../../api/rest/restApiClientModel';
import { ISurveyAnswerOptionModel } from '../../../../answer/survey-answer-option';
import { ICreateQuestionParams, SurveyQuestionTypeEnum } from '../../../types';
import {
	IRadioSurveyQuestionModel,
	RadioSurveyQuestionModel
} from '../radio-survey-question';
import { QuestionsService, SurveyTypes } from '../../../../../../api-client';

export interface IMultiSelectSurveyQuestionModel
	extends IRadioSurveyQuestionModel {
	/**
	 * True if free response should be allowed (adds an Other option to the question)
	 */
	freeResponseAllowed: boolean;
	/**
	 * Answer to the "Other" option, if free-response is allowed
	 */
	freeResponseAnswer: string;
	/**
	 * True if the Free Response answer is valid
	 */
	freeResponseAnswerValid: boolean;
	/**
	 * Minimum number of required options for this question
	 */
	minRequired: number;
	/**
	 * The selected answers to this question
	 */
	selectedAnswers: number[];
	/**
	 * Set free response answer
	 * @param val
	 */
	setFreeResponseAnswer(val: string): void;
	/**
	 * Change the minimum number of required options for this question
	 */
	changeMinRequired(surveyType: string, val: number): void;
}

export class MultiSelectSurveyQuestionModel
	extends RadioSurveyQuestionModel
	implements IMultiSelectSurveyQuestionModel {
	@observable
	public minRequired: number;
	@observable
	freeResponseAllowed: boolean = false;
	@observable
	public freeResponseAnswer: string;

	// type of this question is always multiselect
	public type: SurveyQuestionTypeEnum = SurveyQuestionTypeEnum.Multiselect;

	constructor(params: ICreateQuestionParams) {
		super(params);
		makeObservable(this);
		if (params.questionBankEntry) {
			this.freeResponseAllowed =
				params.questionBankEntry.allowFreeResponse;
			this.minRequired = params.questionBankEntry.minRequired;
		} else {
			this.freeResponseAllowed = params.questionModel.allowFreeResponse;
			this.minRequired = params.questionModel.minRequired;
		}
	}

	public selectOption(answerId: number) {
		// toggle selection for this answer
		const answer = this.options.get(answerId) as ISurveyAnswerOptionModel;
		answer && answer.setSelected(!answer.selected);
	}

	public get validForSubmission(): boolean {
		return (
			(this.freeResponseAnswerValid
				? this.selectedAnswers.length >= this.minRequired - 1
				: this.selectedAnswers.length >= this.minRequired) ||
			// Question can be skipped if optional or if correct number of answers provided
			// Prevents user from providing incorrect number of answers due to optional questions allowing
			// to skip/submit question
			(this.optional &&
				(this.selectedAnswers.length +
					Number(this.freeResponseAnswerValid) >=
					this.minRequired ||
					this.selectedAnswers.length +
						Number(this.freeResponseAnswerValid) ===
						0))
		);
	}

	@action
	public changeMinRequired(surveyType: string, val: number) {
		return RestApiClient.serviceRequest({
			generator: () =>
				QuestionsService.updateQuestionClubsDisplayIdSurveysSurveyTypeQuestionsQidPut(
					{
						displayId: encodeURIComponent(
							AppState.selectedGroup.displayId
						),
						surveyType: surveyType as SurveyTypes,
						qid: this.id,
						requestBody: {
							minRequired: val
						}
					}
				),
			userToken: AppState.user?.cookie
		}).subscribe(
			() => {
				this.minRequired = val;
				AppState.setNotification({
					type: 'success',
					message: 'Successfully updated min. required'
				});
				// capture action
				posthog.capture(
					'Changed Minimum Required Answers for Member Profile (Join Survey) Question',
					{
						group: AppState.selectedGroup?.name,
						source: 'web',
						qid: this.id,
						min_required: val
					}
				);
			},
			() => {
				AppState.setNotification({
					type: 'error',
					message: 'Failed to update min. required'
				});
			}
		);
	}

	/**
	 * Filter out all unselected answers, then map the remaining answers to their answer ids
	 */
	@computed
	public get selectedAnswers(): number[] {
		return Array.from(this.options.values())
			.filter((answer: ISurveyAnswerOptionModel) => answer.selected)
			.map((answer: ISurveyAnswerOptionModel) => answer.id);
	}

	@computed
	public get freeResponseAnswerValid(): boolean {
		return this.freeResponseAnswer?.length > 3;
	}

	@action
	public setFreeResponseAnswer(val: string): void {
		this.freeResponseAnswer = val;
	}
}
