import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RestApiClient } from '../api/rest/restApiClientModel';
import { AppState } from '../../AppModel';
import { AuthService, LoginRequest } from '../../api-client';
import { UserLoginResponse } from '../../api-client/models/UserLoginResponse';

export interface ILoginParams {
	/**
	 * Email to log in this user
	 */
	email?: string;
	/**
	 * Password to log in this user
	 */
	pw?: string;
	/**
	 * Cookie to log in this user
	 */
	cookie?: string;
	/**
	 * Optional: True when we should SUPPRESS saving a cookie
	 */
	suppressSavingCookie?: boolean;
}

export class LoginModel {
	static login(params: LoginRequest): Observable<UserLoginResponse> {
		return RestApiClient.serviceRequest<UserLoginResponse>({
			generator: () =>
				AuthService.loginAuthLoginPost({ requestBody: params })
		}).pipe(
			map((data: UserLoginResponse) => {
				// set the current user
				AppState.setUser(
					data.uid,
					data.cookie,
					data.name?.split(' ')[0],
					data.name?.split(/[ ]+/)[1],
					data.email,
					data.firstLogin,
					true
				);
				return data;
			})
		);
	}
}
